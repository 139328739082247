import React, { ReactNode } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Constrain, GeneralBody } from '../Containers';
import {
	BlogContainer,
	BlogFooter,
	BlogTitle,
	MainDescription,
	Subtitle,
} from './styled';
import Card from '../Card';
import Plugs from './Plugs';

export default function BlogArchive(props: {
	title?: ReactNode;
	subtitle?: ReactNode;
	description?: ReactNode;
	data: GatsbyTypes.BlogAuthorPostsQuery['allMdx'];
}) {
	const { title, subtitle, description, data } = props;
	return (
		<Constrain>
			<GeneralBody>
				<BlogContainer>
					{title ? <BlogTitle>{title}</BlogTitle> : null}
					{subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
					{description ? (
						<MainDescription>{description}</MainDescription>
					) : null}
					<Card.Grid>
						{data.nodes.map(node => (
							<Card
								key={node.id}
								layout={data.nodes.length >= 6 ? 'max-2' : 'full-width'}
								to={`/${node.slug}`}
								image={
									node.frontmatter?.featuredImage?.childImageSharp ? (
										<>
											<GatsbyImage
												image={
													node.frontmatter.featuredImage.childImageSharp
														.gatsbyImageData
												}
												alt={node.frontmatter.heading ?? ''}
											/>
										</>
									) : null
								}
							>
								<Card.Title>{node.frontmatter?.heading}</Card.Title>
								<Card.Description>{node.excerpt}</Card.Description>
								<Card.FakeButton>Continue Reading…</Card.FakeButton>
							</Card>
						))}
					</Card.Grid>
					<BlogFooter>
						<Plugs />
					</BlogFooter>
				</BlogContainer>
			</GeneralBody>
		</Constrain>
	);
}
