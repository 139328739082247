import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import BlogArchive from '../components/Blog/Archive';
import authors from '../data/authors.json';
import NotFound from '../components/NotFound';

type PageContextType = {
	slug: string;
	authorId: string;
};

export default function Template(
	props: PageProps<GatsbyTypes.BlogAuthorPostsQuery, PageContextType>
) {
	const { data, pageContext } = props;
	const author = authors.find(a => a.authorId === pageContext.authorId);
	if (!author) {
		return <NotFound />;
	}
	return (
		<>
			<Seo
				description={author.bio}
				title={`${author.name} - Blog Archive | WPEForm WordPress Form Builder`}
			/>
			<BlogArchive
				data={data.allMdx}
				title={`${author.name} - Blog Archive`}
				subtitle={
					<>
						{data.allMdx.nodes.length} article
						{data.allMdx.nodes.length === 1 ? '' : 's'} published •{' '}
						{author.designation} •{' '}
						<a
							href={`https://twitter.com/${author.twitter}`}
							target="_blank"
							rel="noreferrer nofollow noopener"
						>
							Twitter
						</a>{' '}
						•{' '}
						<a
							href={`https://github.com/${author.github}`}
							target="_blank"
							rel="noreferrer nofollow noopener"
						>
							GitHub
						</a>{' '}
						•{' '}
						<a
							href={`https://www.linkedin.com/in/${author.linkedin}/`}
							target="_blank"
							rel="noreferrer nofollow noopener"
						>
							LinkedIn
						</a>
					</>
				}
				description={author.bio}
			/>
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query BlogAuthorPosts($authorId: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/blog/**/*.mdx" }
				frontmatter: { published: { eq: true }, author: { eq: $authorId } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			nodes {
				id
				slug
				frontmatter {
					date
					heading
					featuredImage {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 850
								placeholder: TRACED_SVG
								aspectRatio: 2.0
							)
						}
					}
				}
				excerpt(pruneLength: 300, truncate: false)
			}
		}
	}
`;
